import slide_1 from '/src/assets/images/OurСouples/slide_1.webp'
import slide_2 from '/src/assets/images/OurСouples/slide_2.webp'
import slide_3 from '/src/assets/images/OurСouples/slide_3.webp'
import slide_4 from '/src/assets/images/OurСouples/slide_4.webp'
import slide_5 from '/src/assets/images/OurСouples/slide_5.webp'
import slide_6 from '/src/assets/images/OurСouples/slide_6.webp'
import slide_7 from '/src/assets/images/OurСouples/slide_7.webp'

export const ourCouplesModel = {
    title: 'Наши семейные пары',
    subtitle: 'Предлагаемая клиенту, семейная пара в дом отбирается нашим агентством тщательно. Мы заинтересованы в удовлетворении клиента, поэтому все семейные пары с проживанием отбираются исходя из предыдущих рекомендаций, образования и других характеристик.',
    offer: 'Подобранная нашим Агентством добросовестная семейная пара в загородный дом заинтересована в долгосрочном сотрудничестве.',
    cards: [
        {
            id: 0,
            image: slide_1,
            title: 'Владимир и Елена',
            description: 'Семейная пара из Рязани, с опытом работы в двух семьях в течении 6 лет. Женщина швея очень вкусно готовит, знание поверхностей, необходимое для качественной уборки. Мужчина имеет водительские права, следит за жизнеобеспечением дома, и за садом.'
        },
        {
            id: 1,
            image: slide_2,
            title: 'Федор и Анастасия',
            description: 'Помощники по хозяйству на длительный срок. Жена: 40 лет генеральная уборка (сухая, влажная) готовка пищи, мелкие поручения. Муж: 43 Охрана территории, уборка территории, топка бани, мелкий ремонт, электрика, сантехника, уход за животными, мелкие поручения. Водительских права В кат. Не конфликтные, добрые, отзывчивые.'
        },
        {
            id: 2,
            image: slide_3,
            title: 'Дмитрий и Александра',
            description: 'Управляющий и помощница по хозяйству. Контролируем работу всех технических систем помещений, вызов ремонтных служб, сопровождение и охрана членов семьи. Проведение тренировок по фитнесу. Уборка и приготовление еды. Выполнение мелких поручений.'
        },
        {
            id: 3,
            image: slide_4,
            title: 'Дмитрий и Мария',
            description: 'Семейная пара. Имеем опыт в уборке территории и дома, контролю за работой оборудования, коммуникаций и тепличным хозяйством. Ответственно выполняем важные поручения.'
        },
        {
            id: 4,
            image: slide_5,
            title: 'Мансур и Гульнара',
            description: 'Честные, трудолюбивые , неконфликтные. Возьмем на себя все заботы по дому. Будем следить за коммуникациями и обеспечивать охрану. Мужчина имеет права категории B'
        },
        {
            id: 5,
            image: slide_6,
            title: 'Юрий и Луиза',
            description: 'Ответственная и трудолюбивая семейная пара. Оба русские. Будем следить за хозяйством в загородном доме. Мужчина электрик и плотник, умеет чистить бассейн и занимается охраной. Девушка занимается приготовлением еды и следит за чистотой в доме.'
        },
        {
            id: 6,
            image: slide_7,
            title: 'Виктор и Анна',
            description: 'Семейная пара из России. Опыт работы 7 лет. Мужчина: водительский стаж-20лет, полное жизнеобеспечение дома, уход за садом, выгул собаки. Женщина: генеральная и поддерживающая уборка дома, стирка, глажка, помощь с детьми.'
        }
    ],
    button: 'Хочу их контакты',
    more: 'Показать еще'
}
