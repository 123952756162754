export const footerModel = {
    title: 'ИЗБА',
    copyright: '© 2023 Все права защещены',
    block: [
        {
            id: 0,
            title: 'ДРУГИЕ УСЛУГИ',
            links: [
                {
                    id: 0,
                    name: 'Профессиональный клининг'
                },
                {
                    id: 1,
                    name: 'Кейтеринг, личный шеф повар'
                },
                {
                    id: 2,
                    name: 'Система умный дом'
                },
                {
                    id: 3,
                    name: 'Профессиональная охрана'
                },
            ]
        },
        {
            id: 1,
            title: 'ДРУГИЕ УСЛУГИ',
            links: [
                {
                    id: 0,
                    name: 'Система видеонаблюдения и безопасности'
                },
                {
                    id: 1,
                    name: 'Организация мероприятий и вечеринок'
                },
                {
                    id: 2,
                    name: 'Детские праздники и анимация'
                },
                {
                    id: 3,
                    name: 'Сантехника/электрика'
                },
            ]
        },
        {
            id: 2,
            title: 'ДРУГИЕ УСЛУГИ',
            links: [
                {
                    id: 0,
                    name: 'Личный водитель и перевозки'
                },
                {
                    id: 1,
                    name: 'Садоводство и ландшафтный дизайн'
                },
                {
                    id: 2,
                    name: 'Фермерство и животноводство'
                },
                {
                    id: 3,
                    name: 'Строительство загородных домов, бытовок и коттеджей'
                },
            ]
        },
    ]
}
