export const lookingJobModel = {
    title: 'Ищете работу?',
    subtitle: 'Основные характеристики, которыми должна обладать семейная пара:',
    info: [
        { id: 0, title: 'Здоровье и трудоспособность' },
        { id: 1, title: 'Техническая грамотность у мужчины' },
        { id: 2, title: 'Отсутствие вредных привычек и проблем с законом' },
        { id: 3, title: 'Аналогичный опыт работы в загородных домах будет преимуществом' },
    ],
    button: 'Хочу у вас работать!'
}
