import slide_1 from '/src/assets/images/Reviews/slide_1.webp'
import slide_2 from '/src/assets/images/Reviews/slide_2.webp'
import slide_3 from '/src/assets/images/Reviews/slide_3.webp'
import slide_4 from '/src/assets/images/Reviews/slide_4.webp'
import slide_5 from '/src/assets/images/Reviews/slide_5.webp'
import slide_6 from '/src/assets/images/Reviews/slide_6.webp'


export const reviewsModel = {
    title: 'Отзывы о нас todo slider',
    cards: [
        {
            id: 0,
            image: slide_1,
            date: '23.08.2023',
            name: 'Андрей Савельев',
            description: 'Я заказал услугу по поиску семейной пары для ухода за моим загородным домом, ' +
                'и был приятно удивлен их профессионализмом и ответственным отношением к работе. Они заботятся о моем доме так, будто это их собственный.'
        },
        {
            id: 1,
            image: slide_2,
            date: '23.08.2023',
            name: 'Валерий',
            description: 'Я заказал услугу по уходу за моим загородным домом, и получил отличного садовника, который умеет ухаживать за цветами и растениями, а также умеет проводить обрезку и стрижку деревьев.',
        },
        {
            id: 2,
            image: slide_3,
            date: '23.08.2023',
            name: 'Милана',
            description: 'Моя семья заказала услугу по уходу за нашим загородным домом, и получили заботливых и ответственных работников, которые не только следят за домом, но и помогают нам в повседневных делах.',
        },
        {
            id: 3,
            image: slide_4,
            date: '23.08.2023',
            name: 'Елена Игоревна',
            description: 'Мы бы хотели выразить благодарность работникам, которых мы наняли и получил отличную семейную пару, которая умеет заботиться о животных. Теперь мои собаки получают много внимания и заботы.',
        },
        {
            id: 4,
            image: slide_5,
            date: '23.08.2023',
            name: 'Лариса Понтелеева',
            description: 'Мне достаточно быстро подобрали семейную пару, которая не только заботится о доме, но и умеет заниматься различными видами спорта. Теперь я могу проводить время активно и здорово вместе с ними.',
        },
        {
            id: 5,
            image: slide_6,
            date: '23.08.2023',
            name: 'Игорь',
            description: 'Я заказал услугу по уходу за моим загородным домом и был удивлен тем, как много свободного времени у меня стало после того, как я начал пользоваться этой услугой. Теперь я могу заниматься своими делами, а не домашним хозяйством.',
        },
    ]
}
