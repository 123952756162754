import image_bg from "/src/assets/images/Hero/hero-bg.webp";

export const heroModel = {
  title: `Подберем семейную пару в загородный дом быстро и качественно`,
  description: '',
  button: "Оставить заявку",
  background_image: image_bg,
  offers: [
    {
      id: 0,
      title: "Бесплатный подбор персонала.",
      subtitle: "Обратившись к нам вы получите работников, удовлетворяющих вашим требованиям, без предоплат, комиссий и скрытых платежей.",
    },
    {
      id: 1,
      title: "Гарантия безопасности.",
      subtitle:
        "Наш приоритет - ваша безопасность, поэтому каждый сотрудник проходит обязательную проверку через нашу службу безопасности.",
    },
    {
      id: 2,
      title: "Онлайн собеседование с семейными парами",
      subtitle:
        "Вы сможете лично поговорить с потенциальными семейными парами, обсудить их навыки, опыт и соответствие вашим требованиям, все это без необходимости выезжать из дома.",
    },
    {
      id: 3,
      title: "Гарантия качества и бесплатной замены",
      subtitle:
        "Мы уверены в высокой компетенции наших работников и готовы бесплатно их заменить, в случае несоответствия вашим ожиданиям.",
    },
  ],
};
