export const responsibilitiesCouple = {
    title: 'Обязанности семейных пар',
    subtitle: 'Многие владельцы загородных домов приглашают в качестве помощников по хозяйству семейные пары, \n' +
        'которые проживают на территории загородного дома и оказывают услуги по уборке, ремонту, \n' +
        'обслуживанию коттеджа и участка. \n' +
        'Круг обязанностей семейной пары может быть разнообразен – всё зависит от особенностей жизни хозяев',
    info: 'Какие еще могут быть обязанности:',
    list: [
            'Уход за домом и прилегающей территории',
            'Уход за растениями, садом и огородом',
            'Приготовление пищи и уборка посуды',
            'Закупка продуктов и предметов бытового назначения',
            'Работа с сервисными службами',
            'Мелкий ремонт',
            'Вождение автомобиля и обслуживание транспортных средств',
            'Уход за домашними животными',
            'Ремонт и обслуживание техники и инвентаря',
            'Уход за детьми. Организация досуга и развлечений',
            'Уход за пожилыми родственниками или людьми с ограниченными возможностями',
            'Оплата счетов',
            'И другое'
    ],
    additionally: 'За счет универсальности, семейная пара может заменить трех или четырех отдельных специалистов.'
}
