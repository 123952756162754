import { server } from './server/index'
import { tgBotSender } from './telegram/index'


const api = {
    server,
    tgBotSender
}

export {
    api
}
